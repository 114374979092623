import Header from "src/components/header/header";
import { CourseModules } from "../../components/courses/course-modules";
import { Footer } from "../../components/footer";
import { FeaturedCourse } from "../../components/featured-course";
import { useContext, useEffect, useState } from "react";
import { LoadingContext } from "src/App";
import { useAuth } from "src/contexts/auth";
import api from "src/services/api";

const MemberIndex = () => {
  const [featuredCourse, setFeaturedCourse] = useState({});
  const [courses, setCourses] = useState([]);

  const [, setIsLoading] = useContext(LoadingContext);

  useEffect(() => {
    setIsLoading(true);
    api
      .get("/courses")
      .then((response) => {
        setCourses(response.data);
        setFeaturedCourse(response.data[0]);
        setIsLoading(false);
      })
      .catch((response) => {
        console.log("catch");
      });
  }, []);

  return (
    <>
      <Header />
      <div className="container mx-auto">
        <div className=" flex flex-col w-full justify-center">
          <FeaturedCourse
            title={featuredCourse.name}
            author={featuredCourse.author}
            id={featuredCourse.id}
            image={
              process.env.REACT_APP_API_URL + "storage/" + featuredCourse.image
            }
          />
        </div>
        <div className="flex flex-col gap-5">
          {courses.map((course) => (
            <CourseModules
              key={course.id}
              hasPermission={course.hasPermission}
              name={course.name}
              modules={course.modules}
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MemberIndex;
