import { Link, useNavigate } from "react-router-dom";
import horizontalLogo from "../../assets/horizontal.svg";
import placeholder from "../../assets/placeholder.png";
import HeaderDrawer from "./drawer";
import { useAuth } from "src/contexts/auth";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import { LogOutIcon } from "lucide-react";
import { toast } from "react-toastify";
import { padString } from "src/lib/utils";

const Header = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <div className="border-b-[1px] border-zinc-800 h-[8vh] sticky top-0 z-50 bg-zinc-900 bg-opacity-45 backdrop-blur">
      <div className="container flex justify-start items-center h-full">
        <div className="flex items-center gap-5">
          <HeaderDrawer />
        </div>
        <Link to="/member">
          <img src={horizontalLogo} alt="logo" className="w-28 ml-5" />
        </Link>
        <div className="flex gap-4 justify-end items-center w-[90%]">
          <p className="text-zinc-300 text-[16px] hidden md:block">
            {auth?.user?.name}
            <span className="text-zinc-500">#{padString(auth?.user?.id)}</span>
          </p>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <img
                src={placeholder}
                alt="placeholder"
                className="w-10 h-10 rounded-full"
                onClick={() => {
                  auth.logout();
                  navigate("/");
                }}
              />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuLabel>
                {auth?.user?.name + "#" + padString(auth?.user?.id)}
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() => {
                  navigate("/perfil");
                }}
              >
                Editar perfil
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  auth.logout();
                  toast("Você foi desconectado com sucesso", {
                    type: "success",
                    icon: "🥷",
                    theme: "dark",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  navigate("/");
                }}
              >
                <LogOutIcon />
                Sair
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
};

export default Header;
