import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}
export function padString(str) {
  const numberString = str.toString();
  return numberString.padStart(4, "0");
}
