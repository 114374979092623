import { createContext, useState } from "react";
import "./App.css";
import AppRouter from "./router";

import anim from "src/assets/anim.gif";
import { AuthProvider } from "./contexts/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const LoadingContext = createContext();

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [test, setTest] = useState("");

  return (
    <>
      <AuthProvider>
        <LoadingContext.Provider
          value={[isLoading, setIsLoading, test, setTest]}
        >
          {isLoading && (
            <div className="flex justify-center items-center w-full h-full bg-black bg-opacity-50 fixed top-0 left-0">
              <img src={anim} alt="loading" className="w-48" />
            </div>
          )}
          <AppRouter />
        </LoadingContext.Provider>
      </AuthProvider>
      <ToastContainer />
    </>
  );
}

export default App;
