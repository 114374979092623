import { Footer } from "src/components/footer";
import Header from "src/components/header/header";
import { Avatar, AvatarFallback, AvatarImage } from "src/components/ui/avatar";
import { Button } from "src/components/ui/button";
import { Card, CardContent } from "src/components/ui/card";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import { User } from "lucide-react";
import api from "src/services/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { padString } from "src/lib/utils";
import { toast } from "react-toastify";

export const Profile = () => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function handleSave() {
    if (confirmPassword === newPassword) {
      api
        .put("/auth/me", {
          password: password,
          new_password: newPassword,
          password_confirmation: confirmPassword,
        })
        .then((response) => {
          toast.success("Senha alterada com sucesso");
          navigate("/member");
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    } else {
      toast.error("As senhas não coincidem");
    }
  }

  useEffect(() => {
    api.get("/auth/me").then((response) => {
      setUser(response.data);
    });
  }, []);

  return (
    <>
      <Header />
      <div className=" flex items-center justify-center mt-20 px-2">
        <Card className="w-full max-w-lg bg-zinc-900 text-zinc-400 border-zinc-800 rounded-3xl">
          <CardContent className="p-6 space-y-6">
            <div className="flex justify-center">
              <Avatar className="w-24 h-24">
                <AvatarImage alt="Profile picture" />
                <AvatarFallback className="bg-zinc-700">
                  <User className="w-12 h-12 text-zinc-500" />
                </AvatarFallback>
              </Avatar>
            </div>

            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="name" className="text-zinc-500">
                  Nome
                </Label>
                <div className="flex items-center">
                  <Input
                    id="name"
                    defaultValue={user.name}
                    className="bg-zinc-800 border-zinc-700 text-zinc-300"
                    disabled
                  />
                  <span className="ml-2 text-sm text-zinc-600">
                    #{padString(user.id ? user.id : 1)}
                  </span>
                </div>
              </div>

              <div className="space-y-2">
                <Label htmlFor="email" className="text-zinc-500">
                  E-mail
                </Label>
                <div className="flex gap-2">
                  <Input
                    id="email"
                    type="email"
                    defaultValue={user.email}
                    disabled
                    className="bg-zinc-800 border-zinc-700 text-zinc-300 flex-grow"
                  />
                </div>
              </div>

              <div className="space-y-2">
                <Label htmlFor="current-password" className="text-zinc-500">
                  Senha atual
                </Label>
                <Input
                  id="current-password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="bg-zinc-800 border-zinc-700 text-zinc-300"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="new-password" className="text-zinc-500">
                  Nova senha
                </Label>
                <Input
                  id="new-password"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="bg-zinc-800 border-zinc-700 text-zinc-300"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="new-password" className="text-zinc-500">
                  Confirmação de senha
                </Label>
                <Input
                  id="confirm-password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="bg-zinc-800 border-zinc-700 text-zinc-300"
                />
              </div>

              <Button
                onClick={handleSave}
                className="w-full bg-zinc-800 text-zinc-300 hover:bg-zinc-700 mt-4"
              >
                Salvar alterações
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
      <Footer />
    </>
  );
};
