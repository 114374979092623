import Header from "src/components/header/header";
import { Footer } from "../../components/footer";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "src/services/api";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "src/components/ui/breadcrumb";
import { Badge } from "src/components/ui/badge";
import { Switch } from "src/components/ui/switch";
import { LoadingContext } from "src/App";

const ModuleBreadcumb = (props) => {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink href="/member">WinnersHub</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink href={"/modulo/" + props.courseId}>
            {props.course}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>{props.lesson}</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export const Lesson = () => {
  const [lesson, setModule] = useState({});
  const lessonId = useParams().id;

  const [, setIsLoading] = useContext(LoadingContext);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    api
      .get("/lesson/" + lessonId)
      .then((response) => {
        setModule(response.data);
        setIsLoading(false);
      })
      .catch((response) => {
        setIsLoading(false);
        toast("Você não tem permissão para acessar essa aula.", {
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "dark",
          draggable: true,
          progress: undefined,
        });

        navigate("/member");
      });
  }, []);

  return (
    <>
      <Header />
      <div className="container py-10 text-white">
        <ModuleBreadcumb
          course={lesson.module?.name}
          courseId={lesson.module?.id}
          lesson={lesson.name}
        />
        <h1 className="text-2xl font-regular mt-2 mb-10">{lesson.name}</h1>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "0px",
            paddingBottom: "56.25%",
          }}
        >
          <iframe
            allow="fullscreen"
            allowFullScreen
            height="100%"
            src={"https://streamable.com/e/" + lesson.video}
            width="100%"
            style={{
              border: "none",
              width: "100%",
              height: "100%",
              position: "absolute",
              left: "0px",
              top: "0px",
              overflow: "hidden",
            }}
          ></iframe>
        </div>
      </div>
      <Footer />
    </>
  );
};
