import horizontalLogo from "src/assets/horizontal.svg";

export const Footer = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-zinc-900 text-white mt-20">
      <div className="container flex flex-col items-center justify-center gap-5 py-10">
        <img src={horizontalLogo} alt="logo" className="w-32" />
        <h1 className="text-sm text-zinc-600">
          WinnersClub — Todos os direitos reservados
        </h1>
      </div>
    </div>
  );
};
