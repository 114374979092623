import { Notify } from "notiflix";
import api from "src/services/api";
import { useNavigate } from "react-router-dom";

const {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} = require("react");

const AuthContext = createContext();

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useMemo(() => {
    const storagedUser = localStorage.getItem("@App:user");
    const storagedToken = localStorage.getItem("@App:token");

    if (storagedToken && storagedUser) {
      setUser(JSON.parse(storagedUser));
      api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
    }
  }, []);

  function logout() {
    setUser(null);

    localStorage.removeItem("@App:user");
    localStorage.removeItem("@App:token");
    api.defaults.headers.Authorization = undefined;
  }

  async function login(email, password) {
    const response = await api.post("/auth/login", {
      email: email,
      password: password,
    });

    setUser(response.data.user);

    api.defaults.headers.Authorization = `Bearer ${response.data.access_token}`;

    localStorage.setItem("@App:user", JSON.stringify(response.data.user));
    localStorage.setItem("@App:token", response.data.access_token);

    return response;
  }

  return (
    <AuthContext.Provider
      value={{ signed: Boolean(user), user, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
