import { IoIosBook, IoLogoWhatsapp, IoMdMenu } from "react-icons/io";

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerOverlay,
  DrawerPortal,
  DrawerTitle,
  DrawerTrigger,
} from "src/components/ui/drawer";

import horizontalLogo from "../../assets/horizontal.svg";
import { Link } from "react-router-dom";
import { SlCallIn } from "react-icons/sl";
import { MessageCircle, Paperclip } from "lucide-react";

const DrawerItem = ({ title, icon, href }) => {
  return (
    <Link to={href} className="flex items-center gap-2 text-white border-1">
      <div className="flex items-center justify-start gap-3 bg-zinc-950 hover:bg-zinc-900 hover:border-zinc-800 transition-all duration-500 border-[1px] border-zinc-900 w-full py-3 px-3 rounded-lg">
        {icon}
        <h1 className="text-white text-lg">{title}</h1>
      </div>
    </Link>
  );
};

export const HeaderDrawer = () => {
  return (
    <Drawer direction="left">
      <DrawerTrigger asChild>
        <IoMdMenu color="white" size={36} />
      </DrawerTrigger>
      <DrawerPortal>
        <DrawerContent className=" flex flex-col rounded-t-[10px] h-full w-[350px] md:w-[400px]">
          <div className="md:p-4 bg-[#050505] flex-1 h-full">
            <div className="max-w-md mx-auto flex flex-col gap-1 px-10 justify-center h-full">
              <DrawerClose asChild>
                <div className="flex justify-center">
                  <img src={horizontalLogo} alt="logo" className="w-32 py-10" />
                </div>
              </DrawerClose>

              <div className="flex flex-col gap-2">
                <DrawerItem
                  title="Cursos"
                  icon={<IoIosBook size={24} color="white" />}
                  href="/member"
                />
                <DrawerItem
                  title="Notas"
                  icon={<Paperclip size={24} color="white" />}
                  href="#"
                />
                <DrawerItem
                  title="WhatsApp"
                  icon={<IoLogoWhatsapp size={24} color="white" />}
                  href="https://api.whatsapp.com/send/?phone=555197240304&text=Opa%20Matheus,%20vim%20da%20plataforma%20da%20Winners%20e%20quero%20acesso%20ao%20grupo%20do%20WhatsApp."
                />
                <DrawerItem
                  title="Discord"
                  icon={<MessageCircle size={24} color="white" />}
                  href="https://discord.gg/9Pnfq4UFUz"
                />
              </div>

              <div className="flex justify-center py-10">
                <h1 className="text-zinc-400">Winners - Vencedores Vencem</h1>
              </div>
            </div>
          </div>
        </DrawerContent>
      </DrawerPortal>
    </Drawer>
  );
};

export default HeaderDrawer;
