import MemberIndex from "../pages/member";
import Login from "../pages/login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Module } from "../pages/member/module";
import { Lesson } from "src/pages/member/lesson";
import { Profile } from "src/pages/member/profile";
const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/member" element={<MemberIndex />} />
        <Route path="/perfil" element={<Profile />} />
        <Route path="/aula/:id" element={<Lesson />} />
        <Route path="/modulo/:id" element={<Module />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
