import { useContext, useEffect, useState } from "react";
import logo from "../assets/horizontal.svg";
import login from "../assets/login.png";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "../App";
import { useAuth } from "src/contexts/auth";
import { Notify } from "notiflix";
import { toast } from "react-toastify";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const authContext = useAuth();
  const [, setIsLoading] = useContext(LoadingContext);

  const navigate = useNavigate();

  useEffect(() => {
    console.log(authContext);
    if (authContext.signed) navigate("/member");
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    authContext
      .login(email, password)
      .then((response) => {
        console.log(response);
        console.log("then");
        setIsLoading(false);
        toast.info("Login efetuado com sucesso!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "dark",
          draggable: true,
          progress: undefined,
        });

        navigate("/member");
      })
      .catch((error) => {
        setIsLoading(false);
        toast("Endereço de e-mail ou senha incorretos", {
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "dark",
          draggable: true,
          progress: undefined,
        });
      });
  }

  return (
    <>
      <div className="flex flex-col justify-center items-center w-full h-screen">
        <div className="flex flex-col justify-start items-center border-[1px] border-zinc-800 rounded-lg w-[90%] md:w-[30%] ">
          <div
            className="flex justify-center items-end pb-5 bg-bottom bg-no-repeat bg-cover w-full min-h-[30%] mb-10 rounded-lg"
            style={{
              backgroundImage: `url(${login})`,
            }}
          >
            <img src={logo} alt="logo" className="w-36 mb-5 rounded-lg" />
          </div>

          <div className="flex flex-col px-10 gap-5 w-full">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col w-full gap-5"
            >
              <Input
                type="email"
                placeholder="Digite seu e-mail"
                className="bg-[#101010] border-zinc-900 h-12 text-zinc-400 focus:border-zinc-800 ring-offset-zinc-800 ring-0 focus-visible:ring-1"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
              <Input
                type="password"
                placeholder="Digite sua senha"
                className="bg-[#101010] border-zinc-900 h-12 text-zinc-400 focus:border-zinc-800 ring-offset-zinc-800 ring-0 focus-visible:ring-1"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <Button className="bg-zinc-100 w-full text-zinc-900 focus:border-zinc-800 ring-offset-zinc-800 ring-0 focus-visible:ring-1 hover:bg-zinc-500 hover:text-zinc-100">
                Entrar na plataforma
              </Button>
            </form>
            <div className="w-full flex flex-col md:flex-row justify-start items-center gap-1 pb-20">
              <a href="/" className="text-zinc-400 hover:text-zinc-200 text-sm">
                Esqueceu sua senha
              </a>
              <p className="text-zinc-700 text-sm">ou</p>
              <a href="/" className="text-zinc-400 hover:text-zinc-200 text-sm">
                ainda não tem uma conta?
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
